import { Outlet } from "react-router-dom";
import { CountryList } from "./CountryList";
import CountryForm from "./CountryForm";
import CityList  from "./CityList";
import CityForm  from "./CityForm";


export const countriesRoutes = [
  {
    path: "countries",
    element: Outlet,
    children: [
      {
        path: "",
        element: CountryList,
      },
      {
        path: "add",
        element: CountryForm,
      },
      {
        path: "edit/:id",
        element: CountryForm,
      },
      {
        path: ":countryID/cities",
        element: CityList,
      },
      {
        path: ":countryID/cities/add",
        element: CityForm,
      },
      {
        path: ":countryID/cities/edit/:cityID",
        element: CityForm,
      },
    ],
  },
];
