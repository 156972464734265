import { CircularProgress } from "@mui/material";
import { snack } from "components/Snack/Snack";
import { getLast12Months } from "helpers/dateHelper";
import React, { useEffect, useState } from "react";
import paymentsService from "services/admin/paymentsService";
import { periods } from "../Stats";

const RevenueMonthly = ({ month }) => {
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState(null)
  const [error, setError] = useState(null)

  useEffect(() => {
    if (data) {
      const ctx = document.getElementById("revenueMonthly");
      const labels = getLast12Months()
      new window.Chart(ctx, {
        type: "bar",
        data: {
          labels: labels,
          datasets: [{
            label: '',
            data: labels.map(l => {
              const value = data.find(x => x.month === l)
              return value ? value.sum : 0
            }),
            backgroundColor: new Array(12).fill('rgba(75, 192, 192, 0.2)'),
            borderColor: new Array(12).fill('rgb(75, 192, 192)'),
            borderWidth: 1
          }]
        },
        options: {
          scales: {
            y: {
              beginAtZero: true
            }
          }
        },
      });
    }
  }, [data]);

  useEffect(() => {
    loadData()
  }, [month])

  async function loadData() {
    try {
      setLoading(true)
      const result = await paymentsService.getRevenueByMonth({ month })
      setData(result.data)
    } catch (ex) {
      console.error(`PaymentMethodStats ex: `, ex)
      snack(ex?.message)
      setError(ex?.message)
    } finally {
      setLoading(false)
    }
  }

  if (loading) {
    return <div className="text-center"><CircularProgress></CircularProgress></div>
  }

  if (error) {
    return <div>{error}</div>
  }

  return (
    <div className="flex-1">
      <h3 className="text-center">Выручка {String(periods.find(p => p.id === month).label).toLowerCase()}, $</h3>
      <p className="color-gray fs-12 m-t-8">Выручка по месяцам — это статистика, показывающая общий доход или прибыль компании по каждому месяцу
        за последний год. Она помогает анализировать сезонные колебания, выявлять тренды роста или спада, а также принимать решения на основе доходности. </p>
      <div style={{maxWidth: '750px'}}>
        <canvas id="revenueMonthly" style={{ width: '100% !important' }}></canvas>
      </div>
    </div>
  );
};

export default RevenueMonthly