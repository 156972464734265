import { useRemoteResource } from "hooks/useRemoteResource";
import withRouter from "hoc/withRouter";
import { CircularProgress } from "@mui/material";
import ListPage from "components/ListPage/ListPage";
import airlinesService from "services/admin/airlinesService";
import AirlineCard from "components/AirlineCard/AirlineCard";

const AirlineList = () => {
    const [data, loadData, loading, error] = useRemoteResource(airlinesService.getAll, "");

    function buildContent() {
        if (error) {
            return <div>{error}</div>
        }
        if (loading) {
            return <div className="text-center"><CircularProgress></CircularProgress></div>
        }
        if (data.data.length === 0) {
            return <div>Авиакомпания отсутсвует. Чтобы добавить авиакомпанию пожалуйста, нажмите на кнопку "Добавить" сверху</div>
        }
        if (data.data.length > 0) {
            return data.data.map((a, inx) => {
                return <div className="m-b-16" key={inx}><AirlineCard key={inx} airline={a}></AirlineCard></div>
            })
        }
    }
    return (
        <ListPage pageTitle="Список авиакомпаний">
            <div className="m-t-32">
                {buildContent()}
            </div>
        </ListPage>

    );
};

export default withRouter(AirlineList);
