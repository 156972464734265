import { withRemoteDataAndSpinner } from "hoc/withRemoteDataAndSpinner";
import withRouter from "hoc/withRouter";
import { useLoadAll } from "hooks/useLoadAll";
import tourPackagesService from "services/admin/tour-packages.service";
import TourPackageCard from "components/TourPackageCard/TourPackageCard";
import AttributeCard from "components/AttributeCard/AttributeCard";
import HotelShortCard from "components/HotelShortCard/HotelShortCard";
import FlightCard from "components/FlightCard/FlightCard";
import { Button, Card, CardContent } from "@mui/material";
import { Link } from "react-router-dom";
import { snack } from "components/Snack/Snack";

function TourPackageView({
    router: {
        navigate,
        params: { id },
    },
    data: { tourPackage },
}) {

    const onDelete = async (item) => {
        try {
            const isConfirmed = window.confirm('Вы точно хотите удалить турпакет?')

            if (isConfirmed) {
                await tourPackagesService.delete(item.id);
                snack('Турпакет успешно удален!')
                navigate('/admin/tour-packages')
            }
        } catch (ex) {
            console.error('При удаление турпакета вышла ошибка:', ex?.message)
        }
    };

    return (
        <div>
            <Card className="p-16">
                <CardContent>
                    <TourPackageCard tourPackage={tourPackage}></TourPackageCard>
                </CardContent>
            </Card>

            <h3 className="m-t-32 m-b-16">Перелеты туда</h3>
            {tourPackage.flightTo ? <div>
                {tourPackage.flightTo.map((f, inx) => {
                    return <FlightCard key={inx} flight={f} options={{ hideButtons: true }}></FlightCard>
                })}
            </div> : <div>Рейсы отсутсвуют</div>}

            <h3 className="m-t-32 m-b-16">Перелеты обратно</h3>
            {tourPackage.returnFlight ? <div>
                {tourPackage.returnFlight.map((f, inx) => {
                    return <FlightCard key={inx} flight={f} options={{ hideButtons: true }}></FlightCard>
                })}
            </div> : <div>Рейсы отсутсвуют</div>}

            <h3 className="m-t-32 m-b-16">Проживание</h3>
            {tourPackage.hotels ? <div>
                {tourPackage.hotels.map((h, inx) => {
                    return <HotelShortCard key={inx} hotel={h} options={{ hideButtons: true }}></HotelShortCard>
                })}
            </div> : <div>Отели отсутсвуют</div>}

            <h3 className="m-t-32 m-b-16">В программу входит</h3>
            {tourPackage.attributes ? <div>
                {tourPackage.attributes.map((a, inx) => {
                    return <AttributeCard key={inx} attribute={a} options={{ hideButtons: true }}></AttributeCard>
                })}
            </div> : <div>Атрибуты отсутсвуют</div>}

            <div className="m-t-32 gap-16 flex">
                <Link to={`/admin/tour-packages/edit/${tourPackage.id}`}>
                    <Button variant="contained" size="small">Редактировать</Button>
                </Link>
                <Link onClick={() => onDelete(tourPackage)}>
                    <Button size="small">Удалить</Button>
                </Link>
            </div>
        </div>
    )
}


export default withRouter(
    withRemoteDataAndSpinner(TourPackageView, (router) => {
        let requests = {
        }
        if (router.params.id) {
            requests['tourPackage'] = () => tourPackagesService.get(router.params.id)
        }
        return useLoadAll(requests);
    })
);
