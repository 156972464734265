import ListPage from "components/ListPage/ListPage";
import { useRemoteResource } from "hooks/useRemoteResource";
import AppAdminTable from "components/AppAdminTable/AppAdminTable";
import Pagination from "components/Pagination/Pagination";
import withRouter from "hoc/withRouter";
import membersService from "services/admin/membersService";
import { TableColumns } from "helpers/tableColumns";
import inputTypeEnum from "enums/inputTypes";
import { CircularProgress } from "@mui/material";
import { Link } from "react-router-dom";

const listConfig = [
  TableColumns.index,
  {
    key: "name",
    label: "ФИО",
    render: (member) => <Link to={`/admin/members/view/${member.id}`}><strong>{member.name}</strong></Link>
  },
  {
    key: "user.email",
    label: "Email",
    inputType: inputTypeEnum.email,
  },
  {
    key: "user.phone",
    label: "Тел.",
    required: true,
    inputType: inputTypeEnum.tel,
  },
  {
    key: "role.name",
    label: "Роль",
  },
  TableColumns.createdDate
]
const MemberList = () => {
  const [data, loadData, loading] = useRemoteResource(membersService.getAll, "");

  const onDelete = async (item) => {
    await membersService.delete(item.id);
    loadData();
  };

  if(loading){
    return <div className="text-center"><CircularProgress></CircularProgress></div>
  }

  return (
    <ListPage pageTitle="Список сотрудников">
      <AppAdminTable
        columnConfig={listConfig}
        data={data?.data || []}
        onDelete={onDelete}
        loading={loading}
      />
      {data && <Pagination total={data.total} limit={50} />}
    </ListPage>
  );
};

export default withRouter(MemberList);
