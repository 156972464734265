import moment from "moment";
import "moment/locale/ru";
moment().local("ru");

export const toDate = (dateAsString) => {
  return moment(dateAsString).format("MMMM DD YYYY, HH:mm");
};

export const fromNow = (dateAsString) => {
  return moment(dateAsString).fromNow(false);
};

/**
 * Date => native format of Browser
 * @param {*} d: Date
 * @returns string
 */
export const toNativeDate = (d) => (d ? moment(d).format("YYYY-MM-DD") : "");
export const toFullDateName = (d) => (d ? moment(d).format("DD MMMM, YYYY") : "");
export const toTime = (d) => (d ? moment(d).format("hh:mm") : "");
export const toDocumentFormat = (d) => (d ? moment(d).format("DD.MM.YYYY") : "");
export const toDocumentFormatWithTime = (d) => (d ? moment(d).format("DD.MM.YYYY HH:mm") : "");
export const toOnlyDate = (d) => (d ? moment(d).format("DD MMMM") : "");
export const diffInDays = (d1, d2 = new Date()) => moment(d1).diff(d2, 'days')

export const expiredInDays = (d1) => {
  if(!d1){
    return null
  }
  const diff = moment(d1).diff(new Date(), 'days')
  if (diff > 0) {
    return `Истекает через ${diff} дней`
  } else {
    return `Истек ${Math.abs(diff)} дней назад`
  }
}

export function getLast12Months() {
  const months = [];
  const currentDate = new Date();

  for (let i = 0; i < 12; i++) {
    // Get the year and month for the current iteration
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    months.push(`${year}-${month}`);

    // Move the date to the previous month
    currentDate.setMonth(currentDate.getMonth() - 1);
  }

  // Reverse to get from oldest to newest
  return months.reverse();
}