import { useRemoteResource } from "hooks/useRemoteResource";
import withRouter from "hoc/withRouter";
import { Alert, CircularProgress } from "@mui/material";
import ListPage from "components/ListPage/ListPage";
import attributesService from "services/admin/attributesService";
import AttributeCard from "components/AttributeCard/AttributeCard";

const AttributeList = () => {
    const [data, loadData, loading, error] = useRemoteResource(attributesService.getAll, "");

    function buildContent() {
        if (error) {
            return <div>{error}</div>
        }
        if (loading) {
            return <div className="text-center"><CircularProgress></CircularProgress></div>
        }
        if (data.data.length === 0) {
            return <div>Атрибуты отсутсвуют. Чтобы добавить атрибут пожалуйста нажмите на кнопку "Добавить" сверху</div>
        }
        if (data.data.length > 0) {
            return data.data.map((h, inx) => {
                return <div className="m-b-16" key={inx}><AttributeCard key={inx} attribute={h} loadData={loadData}></AttributeCard></div>
            })
        }
    }
    return (
        <ListPage pageTitle="Список атрибутов для тур. пакета">
            <Alert color="info" className="m-t-32">
                <strong>Атрибуты турпакета</strong> — это характеристики или свойства, которые описывают содержание и условия предоставления туристического пакета. Они помогают туристам понять, что именно включает в себя пакет, а также позволяют агентствам и операторам четко определить условия предложения
            </Alert>
            <div className="m-t-32">
                {buildContent()}
            </div>
        </ListPage>

    );
};

export default withRouter(AttributeList);
