import { Outlet } from "react-router-dom";
import BookintList from "./BookingList";
import BookingForm from "./BookingForm";
import BookingDetails from "./BookingDetails";
import { adminPaymentsRoutes } from "../Payments/payments-routes";
import { adminUnpaidClientsRoutes } from "../UnpaidClients/unpaid-clients-routes";

export const adminBookingRoutes = [
  {
    path: "bookings",
    element: Outlet,
    children: [
      {
        path: '',
        redirect: 'list'
      },
      {
        path: "list",
        element: BookintList,
      },
      {
        path: "add",
        element: BookingForm,
      },
      {
        path: "edit/:id",
        element: BookingForm,
      },
      {
        path: "view/:id",
        element: BookingDetails,
      },
      ...adminPaymentsRoutes,
      ...adminUnpaidClientsRoutes
    ],
  },
];
