import { useRemoteResource } from "hooks/useRemoteResource";
import withRouter from "hoc/withRouter";
import { CircularProgress } from "@mui/material";
import hotelsService from "services/admin/hotelsService";
import HotelCard from "components/HotelCard/HotelCard";
import ListPage from "components/ListPage/ListPage";

const HotelList = () => {
    const [data, loadData, loading, error] = useRemoteResource(hotelsService.getAll, "");

    function buildContent() {
        if (error) {
            return <div>{error}</div>
        }
        if (loading) {
            return <div className="text-center"><CircularProgress></CircularProgress></div>
        }
        if (data.data.length === 0) {
            return <div>Отели отсутсвуют. Чтобы добавить отель пожалуйста нажмите на кнопку "Добавить" сверху</div>
        }
        if (data.data.length > 0) {
            return data.data.map((h, inx) => {
                return <div key={inx} className="m-b-16">
                    <HotelCard hotel={h} loadData={loadData}></HotelCard>
                </div>
            })
        }
    }
    return (
        <ListPage pageTitle="Список отелей">
            <div className="m-t-32">
                {buildContent()}
            </div>
        </ListPage>

    );
};

export default withRouter(HotelList);
