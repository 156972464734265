import { Outlet } from "react-router-dom";
import MemberList from "./pages/MemberList/MemberList";
import MemberForm from "./pages/MemberForm/MemberForm";
import MemberViewPage from "./pages/MemberView/MemberView";

export const adminMembersRoutes = [
  {
    path: "members",
    element: Outlet,
    children: [
      {
        path: "",
        element: MemberList,
      },
      {
        path: "add",
        element: MemberForm,
      },
      {
        path: "edit/:id",
        element: MemberForm,
      },
      {
        path: "view/:id",
        element: MemberViewPage,
      },
    ],
  },
];
