import { useRemoteResource } from "hooks/useRemoteResource";
import withRouter from "hoc/withRouter";
import { Alert, CircularProgress } from "@mui/material";
import ListPage from "components/ListPage/ListPage";
import flightsService from "services/admin/flightsService";
import FlightCard from "components/FlightCard/FlightCard";

const FlightList = () => {
    const [data, loadData, loading, error] = useRemoteResource(flightsService.getAll, "");

    function buildContent() {
        if (error) {
            return <div>{error}</div>
        }
        if (loading) {
            return <div className="text-center"><CircularProgress></CircularProgress></div>
        }
        if (data.data.length === 0) {
            return <div>Рейсы отсутсвует. Чтобы добавить рейс пожалуйста, нажмите на кнопку "Добавить" сверху</div>
        }
        if (data.data.length > 0) {
            return data.data.map((f, inx) => {
                return <div className="m-b-16" key={inx}>
                    <FlightCard key={inx} flight={f} loadData={loadData}></FlightCard>
                </div>
            })
        }
    }
    return (
        <ListPage pageTitle="Список рейсов">
            <Alert color="info" className="m-t-32">
                <strong>Обратите внимание:</strong> Список рейсов, с которыми работает туристическая компания, включает в себя информацию о доступных авиарейсах, которые могут быть предложены клиентам в рамках туристических пакетов.
            </Alert>
            <div className="m-t-32">
                {buildContent()}
            </div>
        </ListPage>

    );
};

export default withRouter(FlightList);
