import React from 'react'
import { PhotoProvider, PhotoView } from 'react-photo-view'

export const HajjImage = ({ image }) => {
    if (!image) {
        return <span>Фото отсутсвует</span>
    }
    return <PhotoProvider>
        <PhotoView src={image}>
            <div className='hover-scale'>
                <div className='hover-scale-in'>
                    <img style={{ width: '100%' }} alt='' src={image} />
                </div>
            </div>
        </PhotoView>
    </PhotoProvider>


}
