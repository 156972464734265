import { Card, CardContent } from "@mui/material";
import BookingCard from "components/BookingCard/BookingCard";
import { useRemoteResource } from "hooks/useRemoteResource";
import bookingsService from "services/admin/bookingsService";

export const CustomerOrders = ({ customerID }) => {
    const [orders, loadOrders, loading, error] = useRemoteResource(() => bookingsService.getAll({ customerID }))

    return (
        <div className="m-t-32">
            <h3 className="m-b-16">История заказов</h3>
            {
                orders?.data?.map(o => {
                    return <div className="m-b-16">
                        <BookingCard booking={o} options={{ hideButtons: true }}></BookingCard>
                    </div>
                })
            }
        </div>
    )
}
