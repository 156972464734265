import { SuperAdminService } from "services/superAdminService";


class CitiesSAService extends SuperAdminService<any> {
  constructor() {
    super("cities");
  }
}

export default new CitiesSAService();
