import { CircularProgress } from '@mui/material';
import AppAdminTable from 'components/AppAdminTable/AppAdminTable';
import ListPage from 'components/ListPage/ListPage';
import Pagination from 'components/Pagination/Pagination';
import { TableColumns } from 'helpers/tableColumns';
import { withRemoteDataAndSpinner } from 'hoc/withRemoteDataAndSpinner';
import withRouter from 'hoc/withRouter';
import { useLoadAll } from 'hooks/useLoadAll';
import { useRemoteResource } from 'hooks/useRemoteResource';
import citiesSAService from 'services/superadmin/citiesSAService';
import countriesSAService from 'services/superadmin/countriesSAService';

const listConfig = [
  {
    key: "id",
    label: "ID",
  },
  {
    key: "name",
    label: "Город",
  },
  {
    key: "iataCode",
    label: "Код аэрапорта",
  },
  {
    key: "status",
    label: "Статус",
  },
  TableColumns.createdDate
]

const CityList = ({
  data: {
    country
  },
  router
}) => {
  const [data, loadData, loading] = useRemoteResource(() => citiesSAService.getAll({ countryID: router.params.countryID }), "");

  const onDelete = async (item) => {
    await citiesSAService.delete(item.id);
    loadData();
  };

  if (loading) {
    return <div className="text-center"><CircularProgress></CircularProgress></div>
  }

  return (
    <ListPage pageTitle={country.name + `: Список городов`}>
      <AppAdminTable
        columnConfig={listConfig}
        data={data?.data || []}
        onDelete={onDelete}
        loading={loading}
      />
      {data && <Pagination total={data.total} limit={50} />}
    </ListPage>
  );
}


export default withRouter(
  withRemoteDataAndSpinner(CityList, (router) => {
    let requests = {}

    if (router.params.countryID) {
      requests['country'] = () => countriesSAService.get(router.params.countryID)
    }
    return useLoadAll(requests);
  })
);
