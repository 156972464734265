import { Outlet } from "react-router-dom";
import FAQForm from "./FAQForm";
import FAQList from "./FAQList";

export const adminFAQRoutes = [
  {
    path: "faq",
    element: Outlet,
    children: [
      {
        path: "",
        element: FAQList,
      },
      {
        path: "add",
        element: FAQForm,
      },
      {
        path: "edit/:id",
        element: FAQForm,
      },
    ],
  },
];
