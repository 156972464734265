import appAxios from "services/axios";
import { SuperAdminService } from "services/superAdminService";

class OrganizationsSAService extends SuperAdminService {
  constructor() {
    super("orgs");
  }

  deactivate(id) {
    return appAxios.post(`/superadmin/${this.basePath}/${id}/deactivate`);
  }
  activate(id) {
    return appAxios.post(`/superadmin/${this.basePath}/${id}/activate`);
  }
}

export default new OrganizationsSAService();
