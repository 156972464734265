import { Card, CardContent } from '@mui/material'
import TourPackageCard from 'components/TourPackageCard/TourPackageCard'
import React from 'react'

export const TourPackagePreview = ({ tourPackage }) => {
    return (
        <section>
            <div className='m-t-32'>
                <h3 className='m-b-16'>Информация о туре</h3>
                <Card className='p-16'>
                    <CardContent>
                        <TourPackageCard tourPackage={tourPackage}></TourPackageCard>
                    </CardContent>
                </Card>

                {/* {tourPackage.hotels ?
                    tourPackage.hotels.map((h, inx) => {
                        return <HotelShortCard key={inx} hotel={h} options={{ hideButtons: true }}></HotelShortCard>
                    })
                    : null} */}
            </div>
        </section>
    )
}
