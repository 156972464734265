import { Card, CardHeader, CircularProgress } from "@mui/material";
import AppTable from "components/AppTable/AppTable";
import { snack } from "components/Snack/Snack";
import { toFullDateName } from "helpers/dateHelper";
import { useEffect, useState } from "react";
import { statsAdminService } from "services/admin/statsService";

const OrdersByTourpackages = ({ month }) => {
  const columnConfig = [
    {
      key: 'tourPackageName',
      label: 'Турпакет',
    },
    {
      key: 'startDate',
      label: 'Дата с',
      render: (v) => toFullDateName(v.tourpackage.startDate)
    },
    {
      key: 'endDate',
      label: 'Дата по',
      render: (v) => toFullDateName(v.tourpackage.endDate)
    },
    {
      key: 'count',
      label: 'Количество туристов',
    },
  ]

  const [loading, setLoading] = useState(true)
  const [data, setData] = useState(null)
  const [error, setError] = useState(null)

  useEffect(() => {
    loadData()
  }, [month])

  async function loadData() {
    try {
      setLoading(true)
      const result = await statsAdminService.getCountByTourpackages({ month })
      setData(result.data)
    } catch (ex) {
      console.error(`OrdersByTourpackages ex: `, ex)
      snack(ex?.message)
      setError(ex?.message)
    } finally {
      setLoading(false)
    }
  }

  if (loading) {
    return <div className="text-center"><CircularProgress></CircularProgress></div>
  }

  if (error) {
    return <div>{error}</div>
  }

  return (
    <Card style={{ backgroundColor: 'var(--card-background-color)' }}>
      <CardHeader title="Количество туристов по турам"></CardHeader>
      <div className="text-center" style={{ padding: 30 }}>
        <AppTable data={data} columnConfig={columnConfig}></AppTable>
      </div>
    </Card>
  );
};

export default OrdersByTourpackages