import { Button, Card, CardActions, CardContent } from '@mui/material'
import { createMarkup } from 'helpers/componentUtils';
import { Link } from 'react-router-dom';
import organizationsSAService from 'services/superadmin/organizationsSAService';
import _ from 'lodash'
import { snack } from 'components/Snack/Snack';

export default function OrgCard({ org, loadData, options }) {
    const hideButtons = _.get(options, 'hideButtons')

    const onDeactivate = async (item) => {
        try {
            const isConfirmed = window.confirm('Вы точно хотите деактивировать Организацию?')

            if (isConfirmed) {
                await organizationsSAService.deactivate(item.id);
                snack('Организация успешно деактивирована!')
                loadData && loadData()
            }
        } catch (ex) {
            console.error('При изменений организаций вышла ошибка:', ex?.message)
        }
    };

    const onActivate = async (item) => {
        try {
            const isConfirmed = window.confirm('Вы точно хотите активировать Организацию?')

            if (isConfirmed) {
                await organizationsSAService.activate(item.id);
                snack('Организация успешно активирована!')
                loadData && loadData()
            }
        } catch (ex) {
            console.error('При изменений организаций вышла ошибка:', ex?.message)
        }
    };

    return (
        <div>
            <Card className='p-16'>
                <CardContent>
                    <div className='flex gap-8'>
                        <div style={{ width: 250, borderRadius: '8px', overflow: 'hidden', flex: 0.1 }}>
                            {org.logo ? <img style={{ width: '100%' }} alt='' src={org.logo} /> : null}
                        </div>
                        <div className='profile' style={{ flex: 0.7 }}>
                            <h3>{org.name}</h3>
                            <div className='m-t-8'>{org.address}</div>
                            {org.website ? <div className='m-t-8'>
                                <a href={org.website} className='color-blue' rel="noreferrer" target="_blank">{org.website}</a>
                            </div> : <></>}
                            <div className='m-t-16 fs-14' dangerouslySetInnerHTML={createMarkup(org.description)}></div>
                        </div>
                    </div>
                </CardContent>
                {hideButtons ? <></> : <CardActions className='justify-end'>
                    {org.status === "active" ? <Link onClick={() => onDeactivate(org)}>
                        <Button size="small">Деактивировать</Button>
                    </Link> : <Link onClick={() => onActivate(org)}>
                        <Button size="small">Активировать</Button>
                    </Link>}
                </CardActions>}
            </Card>
        </div>
    )
}
