const inputTypeEnum = {
  text: "text",
  select: "select",
  checkbox: "checkbox",
  richText: "richText",
  date: "date",
  datetime: 'datetime',
  hidden: "hidden",
  file: "file",
  email: 'email',
  tel: 'tel',
  time: 'time',
  number: 'number',
  chips: 'chips',
  autocomplete: 'autocomplete',
  autocompleteDynamic: 'autocompleteDynamic'
};

export default inputTypeEnum;
