import { Outlet } from "react-router-dom";
import FlightList from "./FlightList";
import FlightForm from "./FlightForm";

export const adminFlightsRoutes = [
  {
    path: "flights",
    element: Outlet,
    children: [
      {
        path: "",
        element: FlightList,
      },
      {
        path: "add",
        element: FlightForm,
      },
      {
        path: "edit/:id",
        element: FlightForm,
      },
    ],
  },
];
