import { BaseService } from "services/_baseService";
import appAxios from "services/axios";

class CustomersAdminService extends BaseService<any> {
  constructor() {
    super("customers");
  }

  bulkCreate = async (data: any) => {
    return appAxios.post(`${this.pathWithOrg()}/bulk-create`, data);
  };

  getExpiredPassport = async () => {
    return appAxios.get(`${this.pathWithOrg()}/expired-documents/passport`);
  }
  getExpiredVisas = async () => {
    return appAxios.get(`${this.pathWithOrg()}/expired-documents/visas`);
  }
}

export default new CustomersAdminService();
