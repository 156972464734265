import { createSelector } from "@reduxjs/toolkit";

export const getOrg = createSelector(
  (store) => store.auth.user,
  (user) => {
    if (!user) {
      return null
    }

    if (!user.members) {
      return null
    }

    if (localStorage.getItem('orgID')) {
      const selectedOrg = localStorage.getItem('orgID')
      return user.members.find(m => m.orgID === selectedOrg)
    }

    return user?.members[0]
  }
);
