import React from 'react'

export const tripStatus = {
    'CREATED': 'Запланирован',
    'COMPLETED': 'Завершен',
    'CANCELED': 'Отменен'
}

export const TripStatus = ({ status }) => {
    let statusClass = ""
    if (status === 'CREATED') {
        statusClass = 'color-yellow'
    }
    if (status === 'COMPLETED') {
        statusClass = 'color-green'
    }
    if (status === 'CANCELED') {
        statusClass = 'color-gray'
    }
    return <div>
        <span>Статус поездки: </span>
        <span className={`${statusClass}`}>{tripStatus[status]}</span>
    </div>
}
