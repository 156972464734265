import { Button } from "@mui/material";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { loginWithGoogleAction } from "redux/actions/authActions";
import "./login.scss";
import { auth } from "firebaseConfig";
import { GoogleAuthProvider } from "firebase/auth";
import { signInWithPopup } from "firebase/auth";
import { AuthService } from "services/authService";
import { FcGoogle } from "react-icons/fc";
import { FaWhatsapp } from "react-icons/fa";
import { useEffect } from "react";
import { getAuthUser } from "redux/selectors/getUser";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector(getAuthUser);

  useEffect(() => {
    if (user) {
      navigate("/admin/stats");
    }
  }, [user]);

  const signWithGoogle = async () => {
    try {
      const provider = new GoogleAuthProvider();
      const firebaseAuthResponse = await signInWithPopup(auth, provider);
      console.log("firebaseAuthResponse:", firebaseAuthResponse);

      const firebaseUser = firebaseAuthResponse.user;

      const user = {
        email: firebaseUser.email,
      };

      const { data: responseUser } = await AuthService.signInWithGoogle(
        user,
        firebaseUser.accessToken
      );
      console.log("signWithGoogle: ", responseUser);


      if (responseUser.user?.members) {
        const members = responseUser?.user?.members
        if (members && members.length > 0) {
          let orgID = members[0].orgID
          if (localStorage.getItem('orgID')) {
            orgID = localStorage.getItem('orgID')
          }
          localStorage.setItem('orgID', orgID)
        }
      }      

      dispatch(
        loginWithGoogleAction({
          user: responseUser.user,
          token: responseUser.token,
        })
      );
    } catch (ex) {
      console.error("signWithGoogle ex: ", ex);
    }
  };

  return (
    <>
      <Helmet>
        <title>HajjTravel | Сайтқа тіркелу</title>
        <meta name="description" content="Школа программирования в Казахстане" />
      </Helmet>
      <div className="login container login-form-wrapper content">
        <div className="firebase-sign-in">
          <div className="m-b-20 w-100">
            <Link to={`/auth/whatsapp/signin`}>
              <Button className="w-100" style={{ backgroundColor: '#25d366', color: 'white', padding: '8px 0' }}>
                <FaWhatsapp size={20} className="m-r-8"></FaWhatsapp>
                Войти через Whatsapp</Button>
            </Link>
          </div>
          <button type="button" onClick={signWithGoogle}>
            <FcGoogle size={20} />
            Войти через Google
          </button>
        </div>
      </div>
    </>
  );
};

export default Login;
