import appAxios from "services/axios";
import { BaseService } from "../_baseService";
import { toQueryParams } from "helpers/url";

class BookingsAdminService extends BaseService<any> {
  constructor() {
    super(`bookings`);
  }

  completeOrder = async (bookingID: string) => {
    return appAxios.post(`${this.pathWithOrg()}/${bookingID}/complete`, {});
  };
  cancelOrder = async (bookingID: string) => {
    return appAxios.post(`${this.pathWithOrg()}/${bookingID}/cancel`, {});
  };

  refundOrder = async (bookingID: string) => {
    return appAxios.post(`${this.pathWithOrg()}/${bookingID}/refund`, {});
  };
  count = async (queryParams = {}) => {
    const qp = toQueryParams(queryParams)
    return appAxios.get(`${this.pathWithOrg()}/count?${qp}`, {});
  };
  unpaidClients = async (format: string) => {
    return appAxios.get(`${this.pathWithOrg()}/unpaid-clients`, {});
  };
}

export default new BookingsAdminService();
