import { Outlet } from "react-router-dom";
import GroupTrips from "./GroupTrips";
import GroupTripForm from "./GroupTripForm";
import GroupTripDetails from "./GroupTripDetails";

export const adminGroupTripsRoutes = [
  {
    path: "group-trips",
    element: Outlet,
    children: [
      {
        path: "",
        element: GroupTrips,
      },
      {
        path: "add",
        element: GroupTripForm,
      },
      {
        path: "edit/:id",
        element: GroupTripForm,
      },
      {
        path: "view/:id",
        element: GroupTripDetails,
      },
    ],
  },
];
