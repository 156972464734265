import { Button, FormControl, InputAdornment, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import { snack } from 'components/Snack/Snack';
import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import whatsappService from 'services/admin/whatsappService';
import { phoneCountries } from 'services/countryData';

export const WhatsappSignin = () => {
    const countryPrefixRef = useRef()
    const phoneRef = useRef()
    const [isMatched, setIsmatched] = useState(false)
    const navigate = useNavigate()

    async function onsubmit() {
        try {
            const phone = phoneRef.current;
            if (phone) {
                const clearPhone = String(phone.value).replace(/[()-\s]/g, '').trim()
                console.log('clearPhone: ', clearPhone);
                const fullPhoneNumber = `${countryPrefixRef.current.value}${clearPhone}`
                const { data } = await whatsappService.singin(fullPhoneNumber)
                if (data.message) {
                    snack(data.message)
                    setTimeout(() => {
                        navigate(`/auth/whatsapp/verify?phone=${fullPhoneNumber}`)
                    }, 500);
                }
            }
        } catch (ex) {
            console.error('WhatsappSignin ex: ', ex);
            snack('Произошла ошибка, попробуйте позже!', ex?.message)
        }
    }

    return (
        <div className='container m-t-32'>
            <div className='login-form-wrapper'>
                <div className='m-b-64'>
                    <h3>Введите ваш номер телефона Whatsapp</h3>
                </div>
                <div className='phone-number flex flex-col gap-4'>
                    <FormControl fullWidth>
                        <InputLabel>Код страны</InputLabel>
                        <Select defaultValue={`7`} label='Код страны' inputRef={countryPrefixRef}>
                            {phoneCountries.map((p, inx) => {
                                return <MenuItem key={inx} value={p.prefix}>{p.country_name} (+{p.prefix})</MenuItem>
                            })}
                        </Select>
                    </FormControl>
                    <TextField label="Номер телефона" inputRef={phoneRef} className='w-100' placeholder='(777)200-1991' onInput={function (e) {
                        var x = e.target.value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
                        e.target.value = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
                        if (e.target.value.length === 14) {
                            setIsmatched(true)
                        } else {
                            setIsmatched(false)
                        }
                    }}>
                    </TextField>
                </div>
                <div className='w-100 m-t-32'>
                    <Button onClick={onsubmit} variant='contained' className='w-100' disabled={!isMatched}>Отправить код</Button>
                </div>
            </div>
        </div>
    )
}
