import { Outlet } from "react-router-dom";
import { AirportList } from "./AirportList";
import AirportForm from "./AirportForm";

export const airportsRoutes = [
  {
    path: "airports",
    element: Outlet,
    children: [
      {
        path: "",
        element: AirportList,
      },
      {
        path: "add",
        element: AirportForm,
      },
      {
        path: "edit/:id",
        element: AirportForm,
      },      
    ],
  },
];
