import AppForm from "components/AppForm/AppForm";
import CrudPage from "components/CrudPage/CrudPage";
import { withRemoteDataAndSpinner } from "hoc/withRemoteDataAndSpinner";
import withRouter from "hoc/withRouter";
import { useLoadAll } from "hooks/useLoadAll";
import customersService from "services/admin/customersService";
import inputTypeEnum from "enums/inputTypes";
import { snack } from "components/Snack/Snack";
import { phoneCountries } from "services/countryData";
import { phoneToUI } from "hajjtravel-utils";

const CustomerForm = ({ router: { navigate }, data: { customer } }) => {
  const fieldsWithData = [
    {
      key: "user.phonePrefix",
      label: "Код страны",
      required: true,
      inputType: inputTypeEnum.autocomplete,
      options: phoneCountries.map(p => ({ id: p.prefix, name: `${p.country_name} (+${p.prefix})` }))
    },
    {
      key: "user.phone",
      label: "Номер телефона Whatsapp. Будет использоваться как основной вход в систему",
      required: true,
      inputType: inputTypeEnum.tel,
      formatter: (v, getValues) => {
        if (!v) {
          return null
        }
        console.log("prefix: ", getValues("phonePrefix"));
        const prefix = getValues("phonePrefix") || '7'
        if (prefix) {
          return phoneToUI(v, prefix)
        }

        return v
      }
    },
    {
      key: "user.email",
      label: "Почта пользователя если имеется. Например: serik@gmail.com",
      inputType: inputTypeEnum.text,
    },
    {
      key: "user.birthdate",
      label: "Дата рождения",
      inputType: inputTypeEnum.date,
    },
    {
      key: "gender",
      label: "Пол",
      inputType: inputTypeEnum.select,
      options: [
        {
          id: 'F',
          name: 'Женщина'
        },
        {
          id: 'M',
          name: 'Мужчина'
        },
      ]
    },
    {
      key: "photo",
      label: "Фото клиента. Размер должен быть 4x6. Обязательно на белом фоне",
      required: false,
      placeholder: 'Добавьте фото пользователя',
      inputType: inputTypeEnum.file,
    },
    {
      label: (props) => <h3 className="m-t-32 p-t-32" {...props}>Данные об удостоверение личности</h3>
    },
    {
      key: "IDCard.name",
      label: "ФИО(полностью как указан в удостоверение личности)",
      required: true,
      placeholder: 'Введите ФИО пользователя',
      inputType: inputTypeEnum.text,
    },
    {
      key: "IDCard.iin",
      label: "ИИН пользователя",
      inputType: inputTypeEnum.text,
      required: true
    },
    {
      key: "IDCard.photoFront",
      label: "Фото удостоверение личности(передняя часть)",
      required: false,
      inputType: inputTypeEnum.file,
    },
    {
      key: "IDCard.photoBack",
      label: "Фото удостоверение личности(задняя часть)",
      required: false,
      inputType: inputTypeEnum.file,
    },
    {
      label: (props) => <h3 className="m-t-32 p-t-32"  {...props}>Данные о паспорте</h3>
    },
    {
      key: "passport.number",
      label: "Номер паспорта",
      inputType: inputTypeEnum.text,
      required: true
    },
    {
      key: "passport.dateOfIssue",
      label: "Дата выдачи паспорта",
      required: true,
      inputType: inputTypeEnum.date,
    },
    {
      key: "passport.dateOfExpire",
      label: "Срок истечения паспорта",
      required: true,
      inputType: inputTypeEnum.date,
    },
    {
      key: "passport.photo",
      label: "Фото паспорта",
      required: false,
      inputType: inputTypeEnum.file,
    }
  ]

  const onSubmit = async (formData) => {
    try {
      console.log("formData", Object.fromEntries(formData));

      if (customer) {
        await customersService.put(customer.id, formData)
        snack('Пользователь обновлен успешно!')
      } else {
        await customersService.post(formData)
        snack('Пользователь добавлен успешно!')
      }

      setTimeout(() => {
        navigate('/admin/customers')
      }, 500);
    } catch (ex) {
      console.error('Something wrong while adding user', ex)
    }
  };

  return (
    <CrudPage title={customer ? `Изменение клиента` : `Добавление нового клиента`}>
      <AppForm onSubmit={onSubmit} fields={fieldsWithData} data={customer} />
    </CrudPage>
  );
};
export default withRouter(
  withRemoteDataAndSpinner(CustomerForm, (router) => {
    return useLoadAll({
      customer: () => router.params.id ? customersService.get(router.params.id) : null,
    });
  })
);
