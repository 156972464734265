import AppForm from "components/AppForm/AppForm";
import CrudPage from "components/CrudPage/CrudPage";
import { withRemoteDataAndSpinner } from "hoc/withRemoteDataAndSpinner";
import withRouter from "hoc/withRouter";
import { useLoadAll } from "hooks/useLoadAll";
import inputTypeEnum from "enums/inputTypes";
import airlinesService from "services/admin/airlinesService";
import { snack } from "components/Snack/Snack";

const AirlineForm = ({
  router: {
    navigate,
    params: { id },
  },
  data: { airline },
}) => {
  const fieldsWithData = [
    {
      key: "photo",
      label: "Основное фото авиакомпаний",
      inputType: inputTypeEnum.file,
    },
    {
      key: "name",
      label: "Название авиакомпаний",
      required: true,
      inputType: inputTypeEnum.text,
    },
    {
      key: "description",
      label: "Описание",
      inputType: inputTypeEnum.richText,
    },
    {
      key: "website",
      label: "Сайт",      
      inputType: inputTypeEnum.text,
    },
    {
      key: "status",
      label: "Статус",
      inputType: inputTypeEnum.select,
      defaultValue: 'active',
      options: [
        {
          id: 'active',
          name: 'Активный'
        },
        {
          id: 'inactive',
          name: 'Неактивный'
        },
      ]
    }
  ]

  const onSubmit = async (formData) => {
    try {      
      if (airline) {
        await airlinesService.put(id, formData)
        snack("Авиакомпания обновлен успешно!");
      } else {
        await airlinesService.post(formData)
        snack("Авиакомпания создан успешно!");
      }

      setTimeout(() => {
        navigate(-1);
      }, 500);
    } catch (ex) {
      console.error(ex)
    }
  };

  return (
    <CrudPage title={airline ? 'Редактирование авиакомпаний' : 'Создание авиакомпаний'}>
      <AppForm onSubmit={onSubmit} data={airline} fields={fieldsWithData}></AppForm>
    </CrudPage>
  );
};
export default withRouter(
  withRemoteDataAndSpinner(AirlineForm, (router) => {
    let requests = {}
    if (router.params.id) {
      requests['airline'] = () => airlinesService.get(router.params.id)
    }
    return useLoadAll(requests);
  })
);
