import { withRemoteDataAndSpinner } from 'hoc/withRemoteDataAndSpinner';
import withRouter from 'hoc/withRouter';
import { useLoadAll } from 'hooks/useLoadAll';
import MemberCard from 'components/MemberCard/MemberCard';
import membersService from 'services/admin/membersService';

function MemberViewPage({ data: { member } }) {
    return (
        <MemberCard member={member}></MemberCard>
    )
}
export default withRouter(
    withRemoteDataAndSpinner(MemberViewPage, (router) => {
        return useLoadAll({
            member: () => membersService.get(router.params.id)
        });
    })
);
