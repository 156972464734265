import { toQueryParams } from "helpers/url";
import { BaseService } from "services/_baseService";
import appAxios from "services/axios";

class GroupTripAdminService extends BaseService<any> {
  constructor() {
    super(`group-trips`);
  }
  complete(groupTripID: string) {
    return appAxios.post(`/admin/orgs/${localStorage.getItem("orgID")}/group-trips/${groupTripID}/complete`);
  }
  cancel(groupTripID: string) {
    return appAxios.post(`/admin/orgs/${localStorage.getItem("orgID")}/group-trips/${groupTripID}/cancel`);
  }
  count = async (queryParams = {}) => {
    const qp = toQueryParams(queryParams)
    return appAxios.get(`${this.pathWithOrg()}/count?${qp}`);
  };
}

export default new GroupTripAdminService();
