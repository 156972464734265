import { useRemoteResource } from "hooks/useRemoteResource";
import withRouter from "hoc/withRouter";
import { CircularProgress } from "@mui/material";
import ListPage from "components/ListPage/ListPage";
import FAQService from "services/admin/FAQService";
import AppTable from "components/AppTable/AppTable";
import AppAdminTable from "components/AppAdminTable/AppAdminTable";
import { toDate } from "helpers/dateHelper";

const FAQList = () => {
    const columnConfig = [
        {
            label: '#',
            render: (i, index) => index+1
        },
        {
            key: 'name',
            label: 'Вопрос',

        },
        {
            key: 'status',
            label: 'Статус'
        },
        {
            key: 'createdBy',
            label: 'Создан',
            formatter: (v) => v
        },
        {
            key: 'createdAt',
            label: 'Дата создания',
            formatter: (v) => toDate(v)
        }
    ]
    const [data, loadData, loading, error] = useRemoteResource(FAQService.getAll, "");


    const onDelete = async (item) => {
        await FAQService.delete(item.id);
        loadData();
    };

    function buildContent() {
        if (error) {
            return <div>{error}</div>
        }
        if (loading) {
            return <div className="text-center"><CircularProgress></CircularProgress></div>
        }
        if (data.data.length === 0) {
            return <div>Вопросы отсутсвует. Чтобы добавить вопрос пожалуйста, нажмите на кнопку "Добавить" сверху</div>
        }
        if (data.data.length > 0) {
            return <AppAdminTable data={data.data} columnConfig={columnConfig} onDelete={onDelete}></AppAdminTable>
        }
    }
    return (
        <ListPage pageTitle="Список вопросов">
            <div className="m-t-32">
                {buildContent()}
            </div>
        </ListPage>

    );
};

export default withRouter(FAQList);
