import React from 'react'

export const BookingStatus = ({ bookingStatus }) => {
    if (!bookingStatus) {
        return <span>Отсутсвует</span>
    }
    if (bookingStatus.id === 'created') {
        return (
            <span className="color-yellow">{bookingStatus.name}</span>
        )
    }

    if (bookingStatus.id === 'canceled' || bookingStatus.id === 'refund') {
        return (
            <span className="color-red">{bookingStatus.name}</span>
        )
    }

    return (
        <span className="color-green">{bookingStatus.name}</span>
    )
}
