import { CircularProgress } from '@mui/material';
import { CURRENCY_SYMBOL } from 'enums/currencySymbols';
import { withRemoteDataAndSpinner } from 'hoc/withRemoteDataAndSpinner';
import withRouter from 'hoc/withRouter';
import { useLoadAll } from 'hooks/useLoadAll';
import { useRemoteResource } from 'hooks/useRemoteResource';
import React from 'react'
import { currencyRatesService } from 'services/currencyRates';

const CurrencyRates = () => {
    const [currencyRates, loadCurrencyRates, loading, error] = useRemoteResource(()=>currencyRatesService.getAll())
    console.log(`currencyRates: `, currencyRates);

    if (loading) {
        return <div className="text-center"><CircularProgress></CircularProgress></div>
    }

    if(!currencyRates) return null

    const usd = currencyRates.find(c => c.title === 'USD')
    const sar = currencyRates.find(c => c.title === 'SAR')

    return (
        <div className='fs-14 no-mobile'>
            <span>USD: {usd.description} {CURRENCY_SYMBOL.KZT} <span className={`fs-12 ${usd.change > 0 ? `color-green` : `color-red`}`}>({usd.change})</span></span> &nbsp;&nbsp;
            <span>SAR: {sar.description} {CURRENCY_SYMBOL.KZT} <span className={`fs-12 ${sar.change > 0 ? `color-green` : `color-red`}`}>({sar.change})</span></span> &nbsp;&nbsp;
        </div>
    )
}


export default CurrencyRates