import { Outlet } from "react-router-dom";
import OrganizationList from "./OrganizationsList/OrganizationList";
import OrgForm from "pages/Admin/Orgs/OrgForm";

export const organizationsRoutes = [
  {
    path: "organizations",
    element: Outlet,
    children: [
      {
        path: "",
        element: OrganizationList,
      },
      {
        path: "add",
        element: OrgForm,
      },
    ],
  },
];
