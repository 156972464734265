import { AxiosError } from "axios";
import { useEffect, useState } from "react";

export const useRemoteResource = (fetchData, ...params) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  const loadData = (queryParams) => {
    setLoading(true);

    try {
      fetchData(...params, queryParams)
        .then((r) => r.data)
        .then((data) => {
          setData(data);
        })
        .catch((e) => {
          console.log("Try later:", e);
          if (e instanceof AxiosError) {
            setError(e.response.data.error.message)
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (ex) {
      console.log("useRemoteResource: ex ", ex);
      setLoading(false);
      setData(null)
      setError(ex?.message)
    }
  };

  useEffect(loadData, params);

  return [data, loadData, loading, error];
};
