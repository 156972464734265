import axios, { AxiosError } from "axios";
import { snack } from "components/Snack/Snack";
import { logoutAction } from "redux/actions/authActions";
import store from "redux/store";

const appAxios = axios.create({
  baseURL: process.env.REACT_APP_API_ADDRESS ? process.env.REACT_APP_API_ADDRESS + '/api' : '/api',
});

appAxios.interceptors.request.use(
  (config) => {
    // Do something before request is sent
    const { auth } = store.getState();
    config.headers["Authorization"] = "Bearer " + auth.token;

    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);
/**
 * For handling 401: Unauthorization
 */
appAxios.interceptors.response.use(
  function (response) {
    if (response.status === 201) {
      alert("Изменения сохранены!");
    }
    return response;
  },
  function (error) {
    console.log("error.response.status: ", error);
    if (error.response.status === 401) {
      snack(error.response?.data?.error?.message);
      store.dispatch(logoutAction());
      localStorage.removeItem('orgID')
      window.location = "/auth/login";
    } else if (error.response.status === 201) {
      alert("Изменения сохранены!");
    } else {
      console.error("http ex: ", error);
      if (error instanceof AxiosError) {
        console.log(`${error.request?.responseURL} Response error:`, error);
        // alert(error.response.data?.error?.message);
        snack(error.response.data?.error?.message)
      } else {
        alert('Серверная ошибка. Попробуйте позже');
      }
      return Promise.reject(error);
    }
  }
);

export default appAxios;
