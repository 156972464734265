import { Button, Card, CardActions, CardContent } from '@mui/material'
import { Link } from 'react-router-dom'
import _ from 'lodash'
import { phoneToUI } from 'hajjtravel-utils'
import { MdVerified } from 'react-icons/md'

export default function MemberCard({ member }) {
    return (
        <div>
            <Card>
                <CardContent>
                    <div className='flex gap-8'>
                        <div>
                            <img style={{ width: 100, borderRadius: '50%' }} src='/media/images/avatar_m.jpg' alt="" />
                        </div>
                        <div className='profile'>
                            <h3>{member.name}</h3>
                            {member.user?.phone && <div className='m-t-16 flex gap-2'>
                                <span className='color-blue'>+{member.user?.phonePrefix}{phoneToUI(member.user.phone)}</span>
                                <MdVerified className='color-blue fs-16'></MdVerified>
                            </div>}
                            <div className='m-t-8'>{member.user.email}</div>
                        </div>
                    </div>
                </CardContent>
                <CardActions className='justify-end'>
                    <Link to={`/admin/members/edit/${member.id}`}>
                        <Button size="small">Редактировать</Button>
                    </Link>
                </CardActions>
            </Card>

            <Card className='m-t-32'>
                <CardContent>
                    <h3 className='m-b-16'>Является сотрудником</h3>
                    <div className='m-t-8'>
                        <span>Наименование организаций: </span>
                        <span>{member && member.org.name}</span>
                    </div>
                    <div className='m-t-8'>
                        <span>Имеет роль: </span>
                        <span>{member && member.roleID}</span>
                    </div>
                </CardContent>
            </Card>
        </div>
    )
}
