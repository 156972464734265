import React, { useRef, useState } from 'react'
import { IMaskInput } from 'react-imask';
import './phone-input.scss'
import { MenuItem, Select } from '@mui/material';
import { getPhoneNumber } from 'hajjtravel-utils';

const countries = [
    {
        id: '7',
        name: '+7'
    },
    {
        id: '996',
        name: '+996'
    }
]

/**
 * 
 * value - полный номер телефона с кодом: 77772001991
 * code - код страны: Например для Казахстана: 7
 * onChange - функция которая отправляем заполненный телефон
 */
export const PhoneInput = ({ phoneNumber, onChange, countryCode = 7 }) => {
    // Здесь мы извлекаем только номер
    phoneNumber = getPhoneNumber(phoneNumber, countryCode)

    const imaskRef = useRef(null);
    const phoneNumberRef = useRef(null);
    const countryCodeRef = useRef()

    const onComplete = (phone) => {
        const fullPhone = `${countryCodeRef.current.value}${phone}`
        console.log(`fullPhone:`, fullPhone);

        onChange({
            countryCode: countryCodeRef.current.value,
            phone: fullPhone
        })
    }

    return (
        <div className='phone-input'>
            <div className='phone-input__country'>
                <Select name="countryCode" value={countryCode} inputRef={countryCodeRef} onChange={(e) => {
                    onChange({
                        countryCode: e.target.value,
                        phone: ''
                    })
                }}>
                    {countries.map(c => {
                        return <MenuItem key={c.id} value={c.id}>
                            <div className='phone-selected'>
                                <img className="phone-prefix" src="https://ht.kz/img/flag2/kz.png" alt="" />
                                <span>{c.name}</span>
                            </div>
                        </MenuItem>
                    })}
                </Select>
            </div>
            <div className='phone-input__phone'>
                <IMaskInput
                    mask={`(000)000-00-00`}
                    placeholderChar='_'
                    lazy={false}
                    style={{ padding: '1rem' }}
                    value={phoneNumber}
                    unmask={true}
                    ref={imaskRef}
                    inputRef={phoneNumberRef}
                    onComplete={onComplete}
                    placeholder='Номер телефона'
                    onAccept={                        
                        (value, mask) => console.log(value, mask)
                    }
                />
            </div>
        </div>
    )
}
