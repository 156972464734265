import { Outlet } from "react-router-dom";
import { UnpaidClients } from "./UnpaidClients";

export const adminUnpaidClientsRoutes = [
  {
    path: "unpaid-clients",
    element: Outlet,
    children: [
      {
        path: "",
        element: UnpaidClients,
      },
    ],
  },
];
