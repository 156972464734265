import { Button, Card, CardActions, CardContent } from '@mui/material'
import { createMarkup } from 'helpers/componentUtils';
import { Link } from 'react-router-dom';
import hotelsService from 'services/admin/hotelsService';
import { countriesService } from 'services/countries.service';
import _ from 'lodash'
import { HajjImage } from 'components/HajjImage/HajjImage';
import { snack } from 'components/Snack/Snack';

export default function HotelCard({ hotel, options, loadData }) {
    const hideButtons = _.get(options, 'hideButtons')
    const onDelete = async (item) => {
        try {
            const isConfirmed = window.confirm('Вы точно хотите удалить отель?')

            if (isConfirmed) {
                await hotelsService.delete(item.id);
                snack('Отель успешно удален!')
                loadData && loadData()
            }
        } catch (ex) {
            console.error('При удаление отеля вышла ошибка:', ex?.message)
        }
    };
    return (
        <div>
            <Card className='p-16'>
                <CardContent>
                    <div className='flex gap-16'>
                        <div style={{ borderRadius: '8px', overflow: 'hidden', flex: 0.25 }}>
                            <HajjImage image={hotel.photo} />
                        </div>
                        <div className='profile' style={{ flex: 0.7 }}>
                            <h3>{hotel.name}</h3>
                            <div className='m-t-8'>{countriesService.getAll(hotel.country)?.name}</div>
                            <div className='m-t-8'>{hotel.city?.name}</div>
                            <div className='m-t-8'>{hotel.address}</div>
                            {hotel.website ? <div className='m-t-8'>
                                <a href={hotel.website} className='color-blue' rel="noreferrer" target="_blank">{hotel.website}</a>
                            </div> : <></>}
                            <div className='m-t-16 fs-14' dangerouslySetInnerHTML={createMarkup(hotel.description)}></div>
                        </div>
                    </div>
                </CardContent>
                {hideButtons ? <></> : <CardActions className='justify-end'>
                    <Link to={`edit/${hotel.id}`}>
                        <Button size="small">Редактировать</Button>
                    </Link>
                    <Link onClick={() => onDelete(hotel)}>
                        <Button size="small">Удалить</Button>
                    </Link>
                </CardActions>}
            </Card>
        </div>
    )
}
