import { CircularProgress } from '@mui/material';
import AppAdminTable from 'components/AppAdminTable/AppAdminTable';
import ListPage from 'components/ListPage/ListPage';
import Pagination from 'components/Pagination/Pagination';
import { TableColumns } from 'helpers/tableColumns';
import { useRemoteResource } from 'hooks/useRemoteResource';
import { Link } from 'react-router-dom';
import countriesSAService from 'services/superadmin/countriesSAService';

const listConfig = [
    {
        key: "id",
        label: "ID",
        render: (i) => {
            return <Link to={`${i.id}/cities`} className='color-blue'>{i.name}</Link>
        }
    },
    {
        key: "name",
        label: "Cтрана",
    },    
    TableColumns.createdDate
]

export const CountryList = () => {
    const [data, loadData, loading] = useRemoteResource(countriesSAService.getAll, "");

    const onDelete = async (item) => {
        await countriesSAService.delete(item.id);
        loadData();
    };

    if (loading) {
        return <div className="text-center"><CircularProgress></CircularProgress></div>
    }

    return (
        <ListPage pageTitle="Список стран">
            <AppAdminTable
                columnConfig={listConfig}
                data={data?.data || []}
                onDelete={onDelete}
                loading={loading}
            />
            {data && <Pagination total={data.total} limit={50} />}
        </ListPage>
    );
}
