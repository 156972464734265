import appAxios from "./axios";
class CurrencyRatesService {
    data = []

    constructor() {
        this.data = []
    }

    async getAll() {
        const response = await appAxios.get(`/external/currency-rates`)
        this.data = response.data
        console.log(`response.data:`, response.data);
        if (response.data) {
            const usd = response.data.data.find(c => c.title === 'USD')
            if (usd) {
                localStorage.setItem('currancyRate[USD]', usd.description)
            }
        }
        return response.data
    }
}

export const currencyRatesService = new CurrencyRatesService()