import { Card, CardHeader } from "@mui/material";
import AppTable from "components/AppTable/AppTable";
import { toFullDateName } from "helpers/dateHelper";
import { withRemoteDataAndSpinner } from "hoc/withRemoteDataAndSpinner";
import { useRemoteResource } from "hooks/useRemoteResource";
import { statsAdminService } from "services/admin/statsService";

const TotalPaymentsByTourpackages = ({ data }) => {
  const columnConfig = [
    {
      key: 'tourPackageName',
      label: 'Турпакет',
    },
    {
      key: 'startDate',
      label: 'Дата с',
      render: (v) => toFullDateName(v.startDate)
    },
    {
      key: 'endDate',
      label: 'Дата по',
      render: (v) => toFullDateName(v.endDate)
    },
    {
      key: 'total',
      label: 'Общая прибыль',
      formatter: (v) => `${v} $`
    },
  ]
  return (
    <Card style={{ backgroundColor: 'var(--card-background-color)' }}>
      <CardHeader title="Выручка по турам"></CardHeader>
      <div className="text-center" style={{ padding: 30 }}>
        <AppTable data={data} columnConfig={columnConfig}></AppTable>
      </div>
    </Card>
  );
};

export default withRemoteDataAndSpinner(TotalPaymentsByTourpackages, () =>
  useRemoteResource(statsAdminService.getTotalPaymentsByTourpackages)
);
