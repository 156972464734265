import { useRemoteResource } from "hooks/useRemoteResource";
import withRouter from "hoc/withRouter";
import { CircularProgress } from "@mui/material";
import ListPage from "components/ListPage/ListPage";
import { GroupTripCard } from "components/GroupTripCard/GroupTripCard";
import GroupTripService from "services/admin/GroupTripService";

const GroupTrips = () => {
    const [data, loadData, loading, error] = useRemoteResource(GroupTripService.getAll, "");

    function buildContent() {
        if (error) {
            return <div>{error}</div>
        }
        if (loading) {
            return <div className="text-center"><CircularProgress></CircularProgress></div>
        }
        if (data.data.length === 0) {
            return <div>Поездки отсутсвуют. Чтобы добавить поездку пожалуйста, нажмите на кнопку "Добавить" сверху</div>
        }
        if (data.data.length > 0) {
            return data.data.map(t => {
                return <GroupTripCard groupTrip={t}></GroupTripCard>
            })
        }
    }
    return (
        <ListPage pageTitle="Список поездок">
            <div className="m-t-32">
                {buildContent()}
            </div>
        </ListPage>

    );
};

export default withRouter(GroupTrips);
