import { Button, CircularProgress } from "@mui/material";
import { toFormData } from "helpers/formUtils";
import { useRef, useState } from "react";
import customersService from "services/admin/customersService";

const CustomersAddBulk = () => {
  const fileRef = useRef();
  const [isLoading, setIsLoading] = useState(false)

  const onSubmit = async (e) => {
    setIsLoading(true)
    try {
      e.preventDefault();

      console.log("onSubmit: ", fileRef.current.files);
      if (!fileRef.current || !fileRef.current?.files) {
        alert("Пожалуйста выберите csv файл");
        return;
      }

      const file = fileRef.current.files[0];
      if (!file) {
        return alert('Файл не выбран')
      }
      const formedData = toFormData({ file });
      await customersService.bulkCreate(formedData);
      alert("Все клиенты успешно загружены");
      setTimeout(() => {
        window.history.back();
      }, 1000);
    } catch (ex) {
      console.log("ex: ", ex);
    } finally {
      setIsLoading(false)
    }
  };

  return (
    <div>
      <h3 className="mb-4">
        Здесь вы можете загружать клиентов через csv файл.
      </h3>
      <p className="mb-8">Пожалуйста, выберите csv файл, после нажмите на кнопку загрузить. Пример:
        <a
          href="/csv/clients.csv"
          className="color-blue"
          target="_blank"
          style={{ textDecoration: "underline" }}
        >
          csv файла
        </a>{" "}
      </p>
      <form encType="multipart/form-data" onSubmit={onSubmit}>
        <input type="file" accept=".csv" size="100000" ref={fileRef} />
        {isLoading ? <div className="text-center"><CircularProgress></CircularProgress></div> : <Button variant="contained" type="submit">
          Загрузить
        </Button>}
      </form>
    </div>
  );
};
export default CustomersAddBulk;
