import { NavLink } from "react-router-dom";
import { Button as MButton, IconButton, Dialog, DialogTitle, List, ListItem, ListItemAvatar } from "@mui/material";
import { FaMoon } from "react-icons/fa";
import themeState from "states/themeEventTarget";
import { useState } from "react";
import { OrgsDialog } from "components/OrgsDialog/OrgsDialog";

export default function UserMenu({ user }) {
  const [orgSwitcherVisible, setOrgSwitcherVisible] = useState(false)
  const publicMenu = [];
  let menu = [];
  console.log("user:", user);

  if (user) {
    menu = [
      ...publicMenu,
      {
        path: "/admin/profile",
        label: "Профиль",
        component: MButton,
      },
    ];
    if (user.superadmin) {
      menu.push({
        path: "/superadmin/organizations",
        label: "Superadmin",
        component: MButton,
      },)
    }
    if (user.members.length > 0) {
      menu.push({
        onClick: () => {
          setOrgSwitcherVisible(true)
        },
        label: "Организаций",
        component: MButton,
      },)
    }
  } else {
    menu = [
      ...publicMenu,
      {
        path: "/auth/login",
        label: "Войти",
        component: MButton,
      },
    ];
  }

  function toggleDarkMode() {
    themeState.toggle();
  }

  function switchOrg(org) {
    console.log("Selected org:", org);
    localStorage.setItem('orgID', org.id)
    setOrgSwitcherVisible(false)
    window.location.reload()
  }

  return (
    <>
      <div className={"user-menu sm:block xs:hidden mr-2 text-right flex flex-center"}>
        <span className="no-mobile">{user?.email && <span>{user.email}</span>}</span>
        {menu.map((item, menuIndex) => (
          <NavLink to={item.path || '#'} key={menuIndex} onClick={item.onClick}>
            <item.component>
              <i className="fad fa-brain fa-swap-opacity" style={{ marginRight: 10 }}></i>
              {item.label}
            </item.component>
          </NavLink>
        ))}
        <IconButton size="small" onClick={toggleDarkMode}>
          <FaMoon></FaMoon>
        </IconButton>
        {user ? <Dialog onClose={() => { setOrgSwitcherVisible(false) }} open={orgSwitcherVisible}>
          <DialogTitle>Выберите организацию</DialogTitle>
          <OrgsDialog switchOrg={switchOrg}></OrgsDialog>
        </Dialog> : <></>}
      </div>
    </>
  );
}