import { toOnlyDate } from 'helpers/dateHelper'
import moment from 'moment'
import React from 'react'

export const TourPeriod = ({ tourPackage }) => {
    return (
        <div>
            {tourPackage.startDate && tourPackage.endDate ? <div className='m-t-8'>Длительность: <strong>{moment(tourPackage.endDate).diff(moment(tourPackage.startDate), 'days')} ночей</strong></div> : <></>}
            {tourPackage.startDate && tourPackage.endDate ? <div className='m-t-8'>Период: с {toOnlyDate(tourPackage.startDate)} по {toOnlyDate(tourPackage.endDate)}</div> : <></>}
        </div>
    )
}
