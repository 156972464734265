import AppForm from "components/AppForm/AppForm";
import CrudPage from "components/CrudPage/CrudPage";
import { withRemoteDataAndSpinner } from "hoc/withRemoteDataAndSpinner";
import withRouter from "hoc/withRouter";
import { useLoadAll } from "hooks/useLoadAll";
import inputTypeEnum from "enums/inputTypes";
import { snack } from "components/Snack/Snack";
import FAQService from "services/admin/FAQService";

const FAQForm = ({
  router: {
    navigate,
    params: { id },
  },
  data: { faq },
}) => {
  const fieldsWithData = [    
    {
      key: "name",
      label: "Вопрос",
      required: true,
      inputType: inputTypeEnum.text,
    },
    {
      key: "text",
      label: "Текст",
      inputType: inputTypeEnum.richText,
    },    
    {
      key: "status",
      label: "Статус",
      inputType: inputTypeEnum.select,
      value: 'active',
      options: [
        {
          id: 'active',
          name: 'Активный'
        },
        {
          id: 'inactive',
          name: 'Неактивный'
        },
      ]
    }
  ]

  const onSubmit = async (formData) => {
    try {
      if (faq) {
        await FAQService.put(id, formData)
        snack("Вопрос обновлен успешно!");
      } else {
        await FAQService.post(formData)
        snack("Вопрос создан успешно!");
      }

      setTimeout(() => {
        navigate(-1);
      }, 500);
    } catch (ex) {
      console.error(ex)
    }
  };

  return (
    <CrudPage title={faq ? 'Редактирование вопроса' : 'Создание вопроса'}>
      <AppForm onSubmit={onSubmit} data={faq} fields={fieldsWithData}></AppForm>
    </CrudPage>
  );
};
export default withRouter(
  withRemoteDataAndSpinner(FAQForm, (router) => {
    let requests = {}
    if (router.params.id) {
      requests['faq'] = () => FAQService.get(router.params.id)
    }
    return useLoadAll(requests);
  })
);
