import { Outlet } from "react-router-dom";
import AirlineList from "./AirlinesList";
import AirlineForm from "./AirlineForm";

export const adminAirlinesRoutes = [
  {
    path: "airlines",
    element: Outlet,
    children: [
      {
        path: "",
        element: AirlineList,
      },
      {
        path: "add",
        element: AirlineForm,
      },
      {
        path: "edit/:id",
        element: AirlineForm,
      },
    ],
  },
];
