import { Outlet } from "react-router-dom";
import AttributeList from "./AttributeList";
import AttributeForm from "./AttributeForm";

export const adminAttributesRoutes = [
  {
    path: "attributes",
    element: Outlet,
    children: [
      {
        path: "",
        element: AttributeList,
      },
      {
        path: "add",
        element: AttributeForm,
      },
      {
        path: "edit/:id",
        element: AttributeForm,
      },
    ],
  },
];
