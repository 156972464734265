import { Button, Card, CardActions, CardContent } from '@mui/material'
import { TripStatus } from 'components/TripStatus/TripStatus'
import { toDate, toFullDateName } from 'helpers/dateHelper'
import React from 'react'
import { Link } from 'react-router-dom'

export const GroupTripCard = ({ groupTrip }) => {
    if (!groupTrip) {
        return null
    }
    return (
        <Card className='m-b-16'>
            <CardContent>
                <div>
                    <div className='m-b-8 text-right fs-12 color-gray'><span>ID поездки: {groupTrip.tourPackageID}</span></div>
                    <div className='m-b-8'>Выбранный турпакет: <strong>{groupTrip.tourPackage?.name}</strong></div>
                    <div className='m-b-8'>Дата создания поездки: {toDate(groupTrip.createdAt)}</div>
                    <div className='m-b-8'>Дата начало поездки: <strong>{toFullDateName(groupTrip.tourPackage?.startDate)}</strong></div>
                    <div className='m-b-8'>Дата конца поездки: <strong>{toFullDateName(groupTrip.tourPackage?.endDate)}</strong></div>
                    <TripStatus status={groupTrip.status}></TripStatus>
                </div>
                <CardActions className='justify-end'>
                    {groupTrip.status === 'CREATED' ? <Link to={`edit/${groupTrip.id}`}>
                        <Button>Редактировать поездку</Button>
                    </Link> : null}
                    <Link to={`view/${groupTrip.id}`}>
                        <Button>Детали поездки</Button>
                    </Link>
                </CardActions>
            </CardContent>
        </Card>
    )
}
