import { CircularProgress } from '@mui/material'
import AppTable from 'components/AppTable/AppTable'
import { toDocumentFormatWithTime } from 'helpers/dateHelper'
import { useRemoteResource } from 'hooks/useRemoteResource'
import { Link } from 'react-router-dom'
import paymentsService from 'services/admin/paymentsService'
import { toCurrencyFormat } from 'helpers/utils'
import _ from 'lodash'
import { PAYMENT_METHOD } from 'data/paymentMethods'

export const PaymentsHistory = () => {
    const columnConfig = [
        {
            label: '#',
            render: (v, index) => `${index + 1}`
        },
        {
            key: 'createdAt',
            label: 'Дата поступления',
            formatter: (v) => toDocumentFormatWithTime(v)
        },
        {
            key: 'amount',
            label: 'Сумма оплаты',
            render: (i) => toCurrencyFormat(i.amount, i.currency)
        },
        {
            key: 'amountInUSD',
            label: 'Сумма оплаты в долларах',
            render: (i) => toCurrencyFormat(i.amountInUSD, 'USD')
        },
        {
            key: 'exchangeRate',
            label: 'По курсу',
            render: (i) => i.exchangeRate
        },
        {
            key: 'paymentMethod',
            label: 'Способ оплаты',
            render: (i) => PAYMENT_METHOD[i.paymentMethod]
        },
        {
            key: 'status',
            label: 'Статус',
            formatter: (v) => 'Оплачен'
        },
        {
            key: 'tourPackage',
            label: 'Турпакет',
            render: (i) => <Link className='color-blue' to={`/admin/tour-packages/view/${i.booking?.tourPackageID}`}>{_.get(i, 'booking.tourPackage.name', '')}</Link>
        },
        {
            key: 'bookingID',
            label: 'Номер заказа',
            render: (i) => <Link className='color-blue' to={`/admin/bookings/view/${i.bookingID}`}>{i.bookingID}</Link>
        },
        {
            key: 'payerID',
            label: 'Отправитель',
            render: (i) => i.payer ? i.payer.name : null
        },
        {
            key: 'comment',
            label: 'Заметка',
        },
    ]

    const [payments, loadPayments, loading, error] = useRemoteResource(() => paymentsService.getAll({}))

    return loading ? <div className="text-center"><CircularProgress></CircularProgress></div> :
        <div>
            <h3 className='m-b-16'>История платежей</h3>
            <AppTable data={payments.data} columnConfig={columnConfig}></AppTable>
        </div>
}
