import AppForm from "components/AppForm/AppForm";
import CrudPage from "components/CrudPage/CrudPage";
import { withRemoteDataAndSpinner } from "hoc/withRemoteDataAndSpinner";
import withRouter from "hoc/withRouter";
import { useLoadAll } from "hooks/useLoadAll";
import inputTypeEnum from "enums/inputTypes";
import hotelsService from "services/admin/hotelsService";
import { countriesService } from "services/countries.service";
import { citiesService } from "services/cities.service";
import { updateForm } from "hajjtravel-utils";
import { useEffect, useState } from "react";
import _ from "lodash";

const HotelForm = ({
  router: {
    navigate,
    params: { id },
  },
  data: { hotel, cities, countries },
}) => {
  const [fields, setFields] = useState([])

  useEffect(() => {
    let initialFields = [
      {
        key: "name",
        label: "Название отеля",
        required: true,
        inputType: inputTypeEnum.text,
      },
      {
        key: "country",
        label: "Страна",
        inputType: inputTypeEnum.select,
        options: countries ? countries.data : [],
        onChange: async (countryID, fields) => {
          console.log("countryID:", countryID);

          const countryCities = cities.data.filter(x => x.countryID === countryID)
          let updatedFields = updateForm(fields, 'city', 'options', countryCities)
          setFields(updatedFields)
        },
      },
      {
        key: "city",
        label: "Город",
        inputType: inputTypeEnum.select,
        options: cities ? cities.data : []
      },
      {
        key: "address",
        label: "Адрес отеля",
        inputType: inputTypeEnum.text,
      },
      {
        key: "website",
        label: "Сайт",
        inputType: inputTypeEnum.text,
      },
      {
        key: "photo",
        label: "Основное фото отеля",
        inputType: inputTypeEnum.file,
      },
      {
        key: "description",
        label: "Описание",
        inputType: inputTypeEnum.richText,
      },
    ]

    if (hotel) {
      const countryID = _.get(hotel, 'country')
      const countryCities = cities.data.filter(c => c.countryID === countryID)
      initialFields = updateForm(initialFields, 'city', 'options', countryCities)

      setFields(initialFields)
    } else {
      setFields(initialFields)
    }

  }, [])



  const onSubmit = async (formData) => {
    try {
      console.log("formData:", Object.fromEntries(formData));
      if (hotel) {
        await hotelsService.put(id, formData)
        alert("Отель обновлен успешно!");
      } else {
        await hotelsService.post(formData)
        alert("Отель создан успешно!");
      }

      setTimeout(() => {
        navigate(-1);
      }, 500);
    } catch (ex) {
      console.error(ex)
    }
  };

  return (
    <CrudPage title={hotel ? 'Редактирование отеля' : 'Создание отеля'}>
      <AppForm onSubmit={onSubmit} data={hotel} fields={fields}></AppForm>
    </CrudPage>
  );
};
export default withRouter(
  withRemoteDataAndSpinner(HotelForm, (router) => {
    let requests = {
      countries: () => countriesService.getAll(),
      cities: () => citiesService.getAll()
    }
    if (router.params.id) {
      requests['hotel'] = () => hotelsService.get(router.params.id)
    }
    return useLoadAll(requests);
  })
);
