import { Button, Card, CardActions, CardContent, CardHeader } from '@mui/material'
import { toDate, toDocumentFormatWithTime } from 'helpers/dateHelper'
import _ from 'lodash'
import AppTable from './AppTable/AppTable'
import { CURRENCY_SYMBOL } from 'enums/currencySymbols'
import { toCurrencyFormat } from 'helpers/utils'

export const PaymentCard = ({ booking, onClose, options }) => {
    const columnConfig = [
        {
            key: 'createdAt',
            label: 'Дата оплаты',
            formatter: (v) => toDocumentFormatWithTime(v)
        },
        {
            key: 'amount',
            label: 'Сумма оплаты',
            render: (i) => toCurrencyFormat(i.amount, i.currency)
        },
        {
            key: 'amountInUSD',
            label: 'Сумма оплаты в долларах',
            render: (i) => toCurrencyFormat(i.amountInUSD, 'USD')
        },
        {
            key: 'exchangeRate',
            label: 'По курсу',
            render: (i) => i.exchangeRate
        },
        {
            key: 'paymentMethod',
            label: 'Способ оплаты',
            formatter: (v) => v === 'cash' ? 'Наличные' : v
        },
        {
            key: 'status',
            label: 'Статус',
            formatter: (v) => 'Оплачен'
        },
        {
            key: 'comment',
            label: 'Комментарий',
        },
    ]
    const hideButtons = _.get(options, 'hideButtons')


    const totalPayed = () => {
        if (!booking.payments) {
            return 0
        }
        const total = booking.payments.reduce((acc, cur) => {
            return Number(acc) + Number(cur.amountInUSD)
        }, 0)

        return Number(total).toFixed(2)
    }

    const remaining = () => {
        const result = Number(booking.totalPrice - totalPayed())
        if(result === 0){
            return `0 $`
        }
        if (result < 0) {
            return `0 $ (+${Math.abs(result)} $)`
        }
        return toCurrencyFormat(result, 'USD')
    }

    return (
        <Card className='p-16 m-b-32'>
            <CardHeader title="История платежей"></CardHeader>
            <CardContent>
                <AppTable data={booking.payments} columnConfig={columnConfig}></AppTable>
                <div className='m-t-64 flex flex-col text-right'>
                    <div>Общая стоимость тура: <span>{toCurrencyFormat(booking.totalPrice, 'USD')}</span></div>
                    <div>Оплачено: <span>{toCurrencyFormat(totalPayed(), 'USD')}</span></div>
                    <div>Задолжность: <span>{remaining()}</span></div>
                </div>
            </CardContent>
            {hideButtons ? null : <CardActions>
                {['created', 'partialPayment'].includes(booking.bookingStatusID) ? <Button onClick={onClose}>Добавить платеж</Button> : null}
            </CardActions>}
        </Card>
    )
}
