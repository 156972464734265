import { Outlet } from "react-router-dom";
import { PaymentsHistory } from "./PaymentsHistory";

export const adminPaymentsRoutes = [
  {
    path: "payments",
    element: Outlet,
    children: [
      {
        path: "",
        element: PaymentsHistory,
      },
    ],
  },
];
