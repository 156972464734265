import { CircularProgress, List, ListItem, ListItemAvatar } from '@mui/material';
import { useRemoteResource } from 'hooks/useRemoteResource';
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getMeAction } from 'redux/actions/authActions';
import { getAuthUser } from 'redux/selectors/getUser';
import usersService from 'services/usersService';

export const OrgsDialog = ({ switchOrg }) => {
    const displatch = useDispatch()
    const user = useSelector(getAuthUser);

    useEffect(() => {
        displatch(getMeAction())
    }, [])

    return (
        <List>
            {user.members.filter(m => m.org).map((m, inx) => {
                return <ListItem key={inx}>
                    <ListItemAvatar>
                        {m.org.logo ? <img src={m.org.logo} alt="" style={{ width: 50 }} /> : <></>}
                    </ListItemAvatar>
                    <a style={{ textDecoration: "underline" }} onClick={() => switchOrg(m.org)} className="color-blue">{m.org.name}</a>
                </ListItem>
            })}
        </List>
    )
}
