export enum BookingStatusEnum {
    CREATED = 'created',
    PARTIAL_PAYMENT = 'partialPayment',
    FULL_PAYMENT = 'fullPayment',
    REFUND = 'refund',
    CANCELED = 'canceled',
    COMPLETED = 'completed'
}


export enum BookingStatusLabel {
    created = 'Ожидает оплаты',
    partialPayment = 'Оплачено частично',
    fullPayment = 'Полная оплата',
    refund = 'Возврат',
    canceled = 'Отменен',
    completed = 'Завершен'
}