import { Button, InputAdornment, TextField } from '@mui/material'
import { snack } from 'components/Snack/Snack';
import { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { loginWithGoogleAction } from 'redux/actions/authActions';
import whatsappService from 'services/admin/whatsappService';

export const WhatsappVerify = () => {
    const dispatch = useDispatch();
    const passwordRef = useRef()
    const [isMatched, setIsmatched] = useState(false)
    const navigate = useNavigate()
    const [searchParams] = useSearchParams();

    async function onsubmit() {

        const password = passwordRef.current.value;
        const phone = searchParams.get('phone')
        console.log("onSubmit: ", { password, phone });

        if (password) {
            const { data } = await whatsappService.verify(phone, password)
            if (data?.user) {
                console.log("data?.user: ", data?.user);

                if (data.user?.members) {
                    const members = data?.user?.members
                    if (members && members.length > 0) {
                        let orgID = members[0].orgID
                        if (localStorage.getItem('orgID')) {
                            // Если выбран организация до этого войти по нему
                            orgID = localStorage.getItem('orgID')
                        }
                        localStorage.setItem('orgID', orgID)
                    }
                }

                dispatch(
                    loginWithGoogleAction({
                        user: data.user,
                        token: data.token,
                    })
                );

                if (data.user.name) {
                    snack(`Добро пожаловать, ${data.user.name}!`)
                }
                navigate('/admin/customers')
            }
        }
    }

    return (
        <div className='container m-t-32'>
            <div className='login-form-wrapper'>
                <div className='m-b-32'>
                    <h3>Введите 6 значный код подтверждения который вы получили на Whatsapp</h3>
                </div>
                <TextField inputRef={passwordRef} autoComplete="one-time-code" className='w-100' placeholder='Введите код' onInput={function (e) {
                    var v = e.target.value
                    if (v.length === 6) {
                        setIsmatched(true)
                    } else {
                        setIsmatched(false)
                    }
                }}>
                </TextField>
                <div className='w-100 m-t-32'>
                    <Button onClick={onsubmit} variant='contained' className='w-100' disabled={!isMatched}>Проверить</Button>
                </div>
            </div>
        </div>
    )
}
