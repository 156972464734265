import { toQueryParams } from "helpers/url";
import appAxios from "../axios";

export const statsAdminService = {
  getCustomersCount: (queryParams) => {
    const qp = toQueryParams(queryParams)
    return appAxios.get(`/admin/orgs/${localStorage.getItem('orgID')}/customers/count?${qp}`)
  },
  getMembersCount: (queryParams) => {
    const qp = toQueryParams(queryParams)
    return appAxios.get(`/admin/orgs/${localStorage.getItem('orgID')}/members/count${qp}`)
  },
  getToursCount: (queryParams) => {
    const qp = toQueryParams(queryParams)
    return appAxios.get(`/admin/orgs/${localStorage.getItem('orgID')}/tour-packages/count?${qp}`)
  },
  getCountByTourpackages: (queryParams) => {
    const qp = toQueryParams(queryParams)
    return appAxios.get(`/admin/orgs/${localStorage.getItem('orgID')}/bookings/count/tourpackages?${qp}`)
  },
  getTotalPaymentsByTourpackages: (queryParams) => {
    const qp = toQueryParams(queryParams)
    return appAxios.get(`/admin/orgs/${localStorage.getItem('orgID')}/payments/stats/income?${qp}`)
  },
};
