import { Outlet } from "react-router-dom";
import HotelList from "./HotelList/HotelList";
import HotelForm from "./HotelForm/HotelForm";

export const adminHotelsRoutes = [
  {
    path: "hotels",
    element: Outlet,
    children: [
      {
        path: "",
        element: HotelList,
      },
      {
        path: "add",
        element: HotelForm,
      },
      {
        path: "edit/:id",
        element: HotelForm,
      },      
    ],
  },
];
