import { Button, Card, CardActions, CardContent } from '@mui/material'
import { Link } from 'react-router-dom';
import _ from 'lodash'
import flightsService from 'services/admin/flightsService';
import { MdFlightTakeoff } from 'react-icons/md';
import './flight-card.scss'
import { snack } from 'components/Snack/Snack';
import { weeks } from 'pages/Admin/Flights/FlightForm';

function FlightDirectionPreview({ airport, time, label }) {
    if (!airport) {
        return null
    }
    return <div>
        <div className='m-b-8'>{label}:</div>
        <div className='m-b-8'>
            <span className='fs-24 fw-500'>{airport.airportCode}</span>
        </div>
        <div className='m-b-8'>
            <span>{airport.city?.name}</span>
        </div>
        <div className='m-b-8'>
            <span>{time}</span>
        </div>
    </div>
}

export default function FlightCard({ flight, options, loadData }) {
    const hideButtons = _.get(options, 'hideButtons')
    const onDelete = async (item) => {
        try {
            const isConfirmed = window.confirm('Вы точно хотите удалить рейс?')

            if (isConfirmed) {
                await flightsService.delete(item.id);
                snack('Рейс успешно удален!')
                loadData && loadData()

            }
        } catch (ex) {
            console.error('При удаление рейса вышла ошибка:', ex?.message)
            snack(ex?.message)
        }
    };
    return (
        <div>
            <Card className='p-16'>
                <CardContent>
                    <div className='flex gap-8 flex-between flight-info flexr'>
                        <FlightDirectionPreview airport={flight.departureAirport} time={flight.departureTime} label={'От'}></FlightDirectionPreview>
                        {/* To */}
                        <div className='flex flex-col flex-center text-center'>
                            <MdFlightTakeoff size={40} className="boardpass-icon"></MdFlightTakeoff>
                            <div className='fs-16'>{flight.duration} минут в пути</div>
                        </div>
                        <FlightDirectionPreview airport={flight.arrivalAirport} time={flight.arrivalTime} label={`До`}></FlightDirectionPreview>

                        <div className='m-b-8'>
                            <div className='m-b-8'>
                                <img className='airline-icon' src={flight.airline.photo} alt='' />
                            </div>
                            <div>Flight No: {flight.flightNumber}</div>
                            <div className='m-t-16 flex gap-8 fs-12'>
                                {flight.scheduleDays ? 
                                Object.entries(flight.scheduleDays)
                                .filter(([k, v]) => v === true)
                                .map(v => ({ id: v[0], name: v[1]}))
                                .map(d => {
                                return <div>
                                    {weeks.find(dd => dd.id === d.id).name}
                                </div>
                            }) : null}</div>
                        </div>
                    </div>
                </CardContent>
                {hideButtons ? <></> : <CardActions className='justify-end'>
                    <Link to={`edit/${flight.id}`}>
                        <Button size="small">Редактировать</Button>
                    </Link>
                    <Link onClick={() => onDelete(flight)}>
                        <Button size="small">Удалить</Button>
                    </Link>
                </CardActions>}
            </Card>
        </div>
    )
}
