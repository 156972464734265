import { useRemoteResource } from "hooks/useRemoteResource";
import withRouter from "hoc/withRouter";
import { CircularProgress } from "@mui/material";
import ListPage from "components/ListPage/ListPage";
import bookingsService from "services/admin/bookingsService";
import BookingCard from "components/BookingCard/BookingCard";

const BookingList = () => {
    const [bookings, loadData, loading, error] = useRemoteResource(bookingsService.getAll, "");
    
    function buildContent() {
        if (error) {
            return <div>{error}</div>
        }
        if (loading) {
            return <div className="text-center"><CircularProgress></CircularProgress></div>
        }
        if (bookings.data.length === 0) {
            return <div>Заказы отсутсвуют. Чтобы создать заказ пожалуйста, нажмите на кнопку "Добавить" сверху</div>
        }
        if (bookings.data.length > 0) {
            return bookings.data.map((b, inx) => {
                return <div className="m-b-16" key={inx}>
                    <BookingCard
                        key={inx}
                        booking={b}
                        loadData={loadData}></BookingCard>
                </div>
            })
        }
    }
    return (
        <ListPage pageTitle="История заказов" pathToAdd={`../add`}>
            <div className="m-t-32">
                {buildContent()}
            </div>
        </ListPage>

    );
};

export default withRouter(BookingList);
