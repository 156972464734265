import { call, put, takeEvery } from "redux-saga/effects";
import { AuthActions } from "redux/actions/authActions";
import { AuthService } from "services/authService";
import usersService from "services/usersService";

function* login({ payload }) {
  try {
    const response = yield call(AuthService.login, payload);
    yield put({ type: AuthActions.LOGIN_SUCCESS, payload: response.data });
    console.log("response: ", response);

    const userName = response?.data?.user?.name;
    if (response?.data?.user?.orgs) {
      const orgs = response?.data?.user?.orgs
      if (orgs && orgs.length > 0) {
        let orgID = orgs[0].id
        
        if (localStorage.getItem('orgID')) {
          orgID = localStorage.getItem('orgID')
        }
        localStorage.setItem('orgID', orgID)
      }
    }
    userName && alert("Добро пожаловать, " + userName);
  } catch (ex) {
    yield put({
      type: AuthActions.LOGIN_FAILURE,
      payload: ex.message,
    });
  }
}

function* getUserMe() {
  try {
    const response = yield call(usersService.me);
    yield put({ type: AuthActions.ME_SUCCESS, payload: response.data });
  } catch (error) {
    yield put({
      type: AuthActions.ME_FAILURE,
      payload: error.message,
    });
  }
}

function* authSaga() {
  yield takeEvery(AuthActions.LOGIN, login);
  yield takeEvery(AuthActions.ME, getUserMe);
}

export default authSaga;
